.Testimonials{
  background-image: url(./images/background.png);
  background-position: center bottom;
  background-color: rgba(0, 0, 0, 0);
  margin-bottom: 4rem;
}

.Testimonials-container{
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.Testimonials-container-h3{
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  margin-bottom: 0;
}

.Testimonials-container-h3 h3{
  font-weight: 400;
  font-size: 1rem;
  margin: 0;
}

.Testimonials-container-h2{
  height: auto;
  margin: 0;
  margin-bottom: 2.5rem;
  padding: 0;
}

.Testimonials-container-h2 h2{
  color: rgb(11, 65, 65, 1);
  text-align: center;
  width: 100%;
  font-weight: 800;
  font-size: 2rem;
  margin: 0
}

.Testimonials-container-row{
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
}

.Testimonials-container-column-left{
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: .5rem;
}

.Testimonials-container-column-right{
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: .5rem;
}

.Testimonials-container-row-box-1{
  width: 60%;
  background-color: #f5f5f5;
  box-shadow: rgba(0, 0, 0, .25) 11px 11px 50px 0px;
  margin-bottom: .5rem;
  margin-left: auto;
}

.Testimonials-container-row-box-2{
  width: 60%;
  background-color: #f5f5f5;
  box-shadow: rgba(0, 0, 0, .25) 11px 11px 50px 0px;
  margin-top: .5rem;
  margin-left: auto;
}

.Testimonials-container-row-box-3{
  width: 60%;
  background-color: #f5f5f5;
  box-shadow: rgba(0, 0, 0, .25) 11px 11px 50px 0px;
  margin-bottom: .5rem;
  margin-right: auto;
}

.Testimonials-container-row-box-4{
  width: 60%;
  background-color: #f5f5f5;
  box-shadow: rgba(0, 0, 0, .25) 11px 11px 50px 0px;
  margin-top: .5rem;
  margin-right: auto;
}

.Testimonials-container-author-area{
  display: flex;
  flex-direction: row;
  margin: .75rem;
}

.Testimonials-container-row-box-image{
  height: 2rem;
}

.Testimonials-container-row-box-image img {
  height: 100%;
}

.Testimonials-container-author-info-area{
  display: flex;
  flex-direction: column;
  height: 2rem;
  padding-left: 1rem;
  justify-content: space-around;
}

.Testimonials-container-author-info-name{
  color: rgb(11, 65, 65, 1);
  text-align: left;
  font-weight: bold;
  font-size: .8rem;
}

.Testimonials-container-author-info-location{
  color: rgb(11, 65, 65, 1);
  text-align: left;
  font-size: .8rem;
}

.Testimonials-container-row-box-header h4{
  margin: 0;
  font-size: 1rem;
  text-align: left;
  font-style: italic;
}

.Testimonials-container-row-box-header-text-container{
  width: 90%;
  margin: auto;
  background-color: #f5f5f5;
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.Testimonials-container-row-box-header-text{
  font-size: .8rem;
  line-height: 1.25rem;
  color: rgb(11, 65, 65, 1);
  font-weight: 500;
  margin: .75rem;
  text-align: left;
}

@media (max-width: 480px){
  .Testimonials{
    background-image: url(./images/background.png);
    background-position: center bottom;
    background-color: rgba(0, 0, 0, 0);
    margin-bottom: 4rem;
  }

  .Testimonials-container{
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .Testimonials-container-h3{
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5rem;
    margin-bottom: 0;
  }

  .Testimonials-container-h3 h3{
    font-weight: 400;
    font-size: 1rem;
    margin: 0;
  }

  .Testimonials-container-h2{
    height: auto;
    margin: 0;
    margin-bottom: 2.5rem;
    padding: 0;
  }

  .Testimonials-container-h2 h2{
    color: rgb(11, 65, 65, 1);
    text-align: center;
    width: 100%;
    font-weight: 800;
    font-size: 1.75rem;
    margin: 0
  }

  .Testimonials-container-row{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
  }

  .Testimonials-container-column-left{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: .5rem;
  }

  .Testimonials-container-column-right{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: .5rem;
  }

  .Testimonials-container-row-box-1{
    width: 100%;
    background-color: #f5f5f5;
    box-shadow: rgba(0, 0, 0, .25) 11px 11px 50px 0px;
    margin-bottom: .5rem;
    margin-left: auto;
  }

  .Testimonials-container-row-box-2{
    width: 100%;
    background-color: #f5f5f5;
    box-shadow: rgba(0, 0, 0, .25) 11px 11px 50px 0px;
    margin-top: .5rem;
    margin-left: auto;
  }

  .Testimonials-container-row-box-3{
    width: 100%;
    background-color: #f5f5f5;
    box-shadow: rgba(0, 0, 0, .25) 11px 11px 50px 0px;
    margin-bottom: .5rem;
    margin-right: auto;
  }

  .Testimonials-container-row-box-4{
    width: 100%;
    background-color: #f5f5f5;
    box-shadow: rgba(0, 0, 0, .25) 11px 11px 50px 0px;
    margin-top: .5rem;
    margin-right: auto;
  }

  .Testimonials-container-author-area{
    display: flex;
    flex-direction: row;
    margin: .75rem;
  }

  .Testimonials-container-row-box-image{
    height: 2rem;
  }

  .Testimonials-container-row-box-image img {
    height: 100%;
  }

  .Testimonials-container-author-info-area{
    display: flex;
    flex-direction: column;
    height: 2rem;
    padding-left: 1rem;
    justify-content: space-around;
  }

  .Testimonials-container-author-info-name{
    color: rgb(11, 65, 65, 1);
    text-align: left;
    font-weight: bold;
    font-size: .8rem;
  }

  .Testimonials-container-author-info-location{
    color: rgb(11, 65, 65, 1);
    text-align: left;
    font-size: .6rem;
  }

  .Testimonials-container-row-box-header h4{
    margin: 0;
    font-size: 1rem;
    text-align: left;
    font-style: italic;
  }

  .Testimonials-container-row-box-header-text-container{
    width: 90%;
    margin: auto;
    background-color: #f5f5f5;
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .Testimonials-container-row-box-header-text{
    font-size: .55rem;
    line-height: 1rem;
    color: rgb(11, 65, 65, 1);
    font-weight: 500;
    margin: .75rem;
    text-align: left;
    height: 80px;
  }
}
