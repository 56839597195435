.productInfo{
  background-color: #F5FAFF;
}

.productInfo-container{
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.productInfo-container-h2{
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  color: rgb(11, 65, 65, 1);
}

.productInfo-container-h2 h2{
  text-align: center;
  width: 100%;
  font-weight: 800;
  font-size: 2.25rem;
  color: rgb(11, 65, 65, 1);
  margin: 0;
}

.productInfo-container-h3{
  margin-left: auto;
  margin-right: auto;
  height: 20%;
}

.productInfo-container-h3 h3{
  font-weight: 500;
  font-size: 1.25rem;
  margin: 0;
}

.productInfo-container-row{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.productInfo-container-row-box{
  width: 33%;
  display: block;
  text-align: center;
}

.productInfo-container-row-box-image{
  width: 100%;
}

.productInfo-container-row-box-image img {
  height: 231px;
  width: 231px;
}

.productInfo-container-row-box-header{
  margin: auto;
  width: 75%;
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.productInfo-container-row-box-header h4{
  margin: 0;
  font-size: 1.75rem;
  font-weight: 800;
  color: rgb(11, 65, 65, 1);
  cursor: default;
}

.productInfo-container-row-box-header-text-container{
  width: 75%;
  margin: auto;
}

.productInfo-container-row-box-header-text{
  font-size: .9rem;
  line-height: 1.5rem;
  color: rgb(11, 65, 65, 1);
}

@media (max-width: 768px) {
  .productInfo{
    overflow-x: hidden;
  }

  .productInfo-container-h2 {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    color: rgb(11, 65, 65, 1);
  }

  .productInfo-container {
    max-width: 768px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .productInfo-container-row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .productInfo-container-row-box{
    width: 33%;
    display: block;
    text-align: center;
  }

  .productInfo-container-row-box-image img {
    height: 160px;
    width: 160px;
  }
}

@media (max-width: 480px) {
  .productInfo{
    background-color: #F5FAFF;
    overflow-x: hidden;
  }

  .productInfo-container{
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-left:2rem;
    margin-right:2rem;
  }

  .productInfo-container-h2{
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    color: rgb(11, 65, 65, 1);
  }

  .productInfo-container-h2 h2{
    text-align: center;
    width: 100%;
    font-weight: 800;
    font-size: 1.75rem;
    color: rgb(11, 65, 65, 1);
    margin: 0;
  }

  .productInfo-container-h3{
    margin-left: auto;
    margin-right: auto;
    height: 20%;
  }

  .productInfo-container-h3 h3{
    font-weight: 500;
    font-size: 1.25rem;
    margin: 0;
  }

  .productInfo-container-row{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .productInfo-container-row-box{
    width: 33%;
    display: block;
  }

  .productInfo-container-row-box-image{
    width: 100%;
  }

  .productInfo-container-row-box-image img {
    width: 75%;
    margin: auto;
    height: 72px;
  }

  .productInfo-container-row-box-header{
    margin: auto;
    width: 80%;
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .productInfo-container-row-box-header h4{
    margin: 0;
    font-size: .65rem;
    font-weight: 800;
    color: rgb(11, 65, 65, 1);
  }

  .productInfo-container-row-box-header-text-container{
    width: 75%;
    margin: auto;
  }

  .productInfo-container-row-box-header-text{
    font-size: .5rem;
    font-weight: 700;
    line-height: 1rem;
    color: rgb(11, 65, 65, 1);
  }
}
