.box-info-section{
  background-color: #FFFFFF;
}

.box-info-section-box{
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
  line-height: 2;
  color: black;
}

.right-box{
  width: 60%;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 2.5rem;
  flex-direction: column;
  align-self: center;
  display: block;
}

.right-box-box{
  width: 60%;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 2.5rem;
  flex-direction: column;
  align-self: center;
  display: block;
}

.right-box-h2-holder{
  padding-left: 2.5rem;
  padding-right: 0;
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 1.25;
  margin-bottom: 1rem;
  padding-top: 1.5rem;
  letter-spacing: -.025rem;
  display: block;
}

.right-box-h2-holder h2{
  margin: 0;
  color: rgb(11, 65, 65, 1);
  font-weight: 800;
}

.right-box-box-h2{
  font-size: 2rem;
  width: 100%;
}

.right-box-p-holder{
  padding-left: 2.5rem;
  padding-right: 0;
  line-height: 1.75;
}

.right-box-p-holder p{
  margin: 0;
  margin-bottom: 1rem;
  text-align: left;
  color: rgb(11, 65, 65, 1);
}

.right-box-a-holder{
  margin: auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  padding-left: 2.5rem;
  padding-top: 2.5rem;
}

.right-box-yellow-action-button{
  padding: .75rem 3rem;
  margin-left: 0rem;
  background-color: #0b4141;
  border: 1px solid #0b4141;
  cursor: pointer;
  outline: 0;
  text-decoration: none;
  color: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  border-radius: 9999px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375;
}

.right-box-yellow-action-button:hover{
  background-color: #ffffff;
  color: rgb(11,65,65, 1);
  border: 1px solid rgb(11,65,65, 1);
}

.left-box{
  width: 40%;
  padding: 2.5rem;
  padding-right: 0;
  padding-left: 0;
  flex-direction: column;
  align-self: center;
  line-height: 2;
  display: block;
}

.left-box-box{
  width: 40%;
  padding: 2.5rem;
  padding-left: 0;
  padding-right: 0;
  flex-direction: column;
  align-self: center;
  line-height: 2;
  display: block;
}

.box-image{
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
}

.box-image-2{
  max-width: 100%;
  height: auto;
  display: block;
  margin-right: auto;
}

@media (max-width: 768px) {
  .box-info-section{
    background-color: #FFFFFF;
  }

  .box-info-section-box{
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;
    line-height: 2;
    color: black;
  }

  .right-box{
    width: 60%;
    padding-left: 1.5rem;
    padding-right: 4rem;
    padding-bottom: 2.5rem;
    flex-direction: column;
    align-self: center;
    display: block;
  }

  .right-box-box{
    width: 60%;
    padding-left: 4rem;
    padding-right: 1.5rem;
    padding-bottom: 2.5rem;
    flex-direction: column;
    align-self: center;
    display: block;
  }

  .right-box-h2-holder{
    padding-left: 0rem;
    padding-right: 0;
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 1.25;
    margin-bottom: 1rem;
    padding-top: 2.5rem;
    letter-spacing: -.025rem;
    display: block;
  }

  .right-box-h2-holder h2{
    margin: 0;
    color: rgb(11, 65, 65, 1);
    font-size: 1.75rem;
  }

  .right-box-box-h2{
    font-size: 2rem;
    font-weight: 600;
    width: 100%;
  }

  .right-box-p-holder{
    padding-left: 0rem;
    padding-right: 0;
    line-height: 1.75;
  }

  .right-box-p-holder p{
    margin: 0;
    margin-bottom: 1rem;
    text-align: left;
    font-size: 1rem;
    color: rgb(11, 65, 65, 1);
  }

  .right-box-a-holder{
    margin: auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    padding-left: 0rem;
    padding-top: 2rem;
  }

  .right-box-yellow-action-button{
    padding: .75rem 1.875rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: 0rem;
    background-color: rgb(11,65,65, 1);
    border: 1px solid rgb(11,65,65, 1);
    cursor: pointer;
    outline: 0;
    text-decoration: none;
    color: white;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
    border-radius: 9999px;
    font-weight: 700;
    font-size: .75rem;
    line-height: 1.375;
    display: inline-block;
    clear: both;
    white-space: nowrap;
  }

  .right-box-yellow-action-button:hover{
    background-color: #ffffff;
    color: rgb(11,65,65, 1);
    border: 1px solid rgb(11,65,65, 1);
  }

  .left-box{
    width: 40%;
    padding: 2.5rem;
    padding-right: 0;
    padding-left: 4rem;
    flex-direction: column;
    align-self: center;
    line-height: 2;
    display: block;
  }

  .left-box-box{
    width: 40%;
    padding: 2.5rem;
    padding-right: 4rem;
    padding-left: 0rem;
    flex-direction: column;
    align-self: center;
    line-height: 2;
    display: block;
  }

  .box-image{
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
  }

  .box-image-2{
    max-width: 100%;
    height: auto;
    display: block;
    margin-right: auto;
  }
}

@media (max-width: 480px) {
  .box-info-section{
    background-color: #FFFFFF;
  }

  .box-info-section-box{
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    line-height: 2;
    color: black;
  }

  .right-box{
    width: 60%;
    padding-left: 1.5rem;
    padding-right: 2rem;
    padding-bottom: 2.5rem;
    flex-direction: column;
    align-self: center;
    display: block;
  }

  .right-box-box{
    width: 60%;
    padding-left: 2rem;
    padding-right: 1.5rem;
    padding-bottom: 2.5rem;
    flex-direction: column;
    align-self: center;
    display: block;
  }

  .right-box-h2-holder{
    padding-left: 0rem;
    padding-right: 0;
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 1.25;
    margin-bottom: 1rem;
    padding-top: 1.5rem;
    letter-spacing: -.025rem;
    display: block;
  }

  .right-box-h2-holder h2{
    margin: 0;
    color: rgb(11, 65, 65, 1);
    font-size: 1.75rem;
  }

  .right-box-box-h2{
    font-size: 2rem;
    font-weight: 600;
    width: 100%;
  }

  .right-box-p-holder{
    padding-left: 0rem;
    padding-right: 0;
    line-height: 1.75;
  }

  .right-box-p-holder p{
    margin: 0;
    margin-bottom: 1rem;
    text-align: left;
    font-size: .6rem;
    color: rgb(11, 65, 65, 1);
  }

  .right-box-a-holder{
    margin: auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    padding-left: 0rem;
    padding-top: 2rem;
  }

  .right-box-yellow-action-button{
    padding: .75rem 1.875rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: 0rem;
    background-color: rgb(11,65,65, 1);
    border: 1px solid rgb(11,65,65, 1);
    cursor: pointer;
    outline: 0;
    text-decoration: none;
    color: white;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
    border-radius: 9999px;
    font-weight: 700;
    font-size: .75rem;
    line-height: 1.375;
    display: inline-block;
    clear: both;
    white-space: nowrap;
  }

  .right-box-yellow-action-button:hover{
    background-color: #ffffff;
    color: rgb(11,65,65, 1);
    border: 1px solid rgb(11,65,65, 1);
  }

  .left-box{
    width: 40%;
    padding: 2.5rem;
    padding-right: 0;
    padding-left: 2rem;
    flex-direction: column;
    align-self: center;
    line-height: 2;
    display: block;
  }

  .left-box-box{
    width: 40%;
    padding: 2.5rem;
    padding-right: 2rem;
    padding-left: 0rem;
    flex-direction: column;
    align-self: center;
    line-height: 2;
    display: block;
  }

  .box-image{
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
  }

  .box-image-2{
    max-width: 100%;
    height: auto;
    display: block;
    margin-right: auto;
  }
}
