section{
  background-color: rgba(0,0,0,0)
}

.background-image-div{
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  background-size: contain;
  background-repeat: no-repeat;
  height: 419px;
  background-position: 100% 100%;
  box-sizing: border-box;
  border: 0 solid;
  display: block;
  --bg-opacity: 1;
  line-height: 2;
}

.max-width{
  background-color: transparent;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
  box-sizing: border-box;
  border: 0 solid;
}

.full-width{
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border: 0 solid;
  flex-direction: column;
  margin-left: 6rem;
  margin-right: 6rem;
}

.text-holder{
  background-color: transparent;
  padding-top: 45px;
  width: 100%;
  box-sizing: border-box;
  border: 0 solid;
  display: block;
  justify-content: flex-start;
}

.secondary-row-holder{
  display: flex;
  flex-direction: row;
}

.secondary-text-holder{
  margin-top: 4rem;
  width: 60%;
}

.header-image-holder{
  margin-top: -10rem;
  width: 50%;
}

h1{
  width: 70%;
  text-align: left;
  font-size: 3rem;
  --text-opacity: 1;
  font-weight: 700;
  line-height: 1.24;
  margin-bottom: 1rem;
  color: rgb(11, 65, 65, 1);
  margin-top: 0;
  padding-bottom: 5rem;
}

.main-h2{
  width: 80%;
  letter-spacing: 0;
  line-height: 1.5;
  font-weight: 700;
  margin-bottom: .25rem;
  font-size: 1.75rem;
  text-align: left;
  color: rgb(255, 204, 188, 1);
  margin-left: 1rem;
}

.button-holder{
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  box-sizing: border-box;
  border: 0 solid;
  line-height: 2;
}

.yellow-action-button{
  align-self: center;
  padding: .75rem 1.875rem;
  padding-left: 3rem;
  padding-right: 3rem;
  margin: 1.25rem;
  margin-left: 0;
  align-self: flex-start;
  background-color: #FDFF74;
  cursor: pointer;
  outline: 0;
  text-decoration: none;
  color: black;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  border-radius: 9999px;
  font-weight: 600;
  font-size: 1rem;
  width: auto;
  line-height: 1.375;
}

.yellow-action-button:hover{
  background-color: #ffffff;
}

.white-action-button{
  align-self: center;
  padding: .75rem 1.875rem;
  padding-left: 3rem;
  padding-right: 3rem;
  align-self: flex-start;
  margin: 1.25rem;
  margin-left: 0;
  background-color: #FFFFFF;
  cursor: pointer;
  outline: 0;
  text-decoration: none;
  color: black;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  border-radius: 9999px;
  font-weight: 600;
  font-size: 1rem;
  width: auto;
  line-height: 1.375;
  align-self: flex start;
}

.white-action-button:hover{
  background-color: grey;
}

@media (max-width: 768px){

  .full-width{
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border: 0 solid;
    flex-direction: column;
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .main-h1{
    width: 60%;
    text-align: left;
    font-size: 2.5rem;
    --text-opacity: 1;
    font-weight: 700;
    line-height: 1.24;
    /* margin-bottom: 1rem; */
    color: rgb(11, 65, 65, 1);
    padding-bottom: 0rem;
  }

  .text-holder{
    background-color: transparent;
    padding-top: 100px;
    width: 100%;
    box-sizing: border-box;
    border: 0 solid;
    display: block;
    justify-content: flex-start;
  }

  .secondary-text-holder{
    margin-top: 9rem;
    width: 60%;
  }

  .main-h2{
    width: 90%;
    letter-spacing: 0;
    line-height: 1.5;
    font-weight: 700;
    margin-bottom: .25rem;
    font-size: 1.7rem;
    text-align: left;
    color: rgb(255, 204, 188, 1);
    margin-left: 0rem;
    margin-block-start: 0rem;
    margin-block-end: 0rem;
  }

  .header-image-holder{
    margin-top: 0rem;
    width: 40%;
  }

  .yellow-action-button{
    align-self: center;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 1.25rem;
    margin-left: 0;
    align-self: flex-start;
    background-color: #FDFF74;
    cursor: pointer;
    outline: 0;
    text-decoration: none;
    color: black;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
    border-radius: 9999px;
    font-weight: 600;
    font-size: 1rem;
    width: auto;
    line-height: 1.375;
  }

  .white-action-button{
    align-self: center;
    padding: .75rem 1.875rem;
    padding-left: 3rem;
    padding-right: 3rem;
    align-self: flex-start;
    margin: 1.25rem;
    margin-left: 0;
    background-color: #FFFFFF;
    cursor: pointer;
    outline: 0;
    text-decoration: none;
    color: black;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
    border-radius: 9999px;
    font-weight: 600;
    font-size: 1rem;
    width: auto;
    line-height: 1.375;
    align-self: flex start;
  }

}

@media (max-width: 480px) {
  section{
    background-color: rgba(0,0,0,0)
  }

  .background-image-div{
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    background-size: contain;
    background-repeat: no-repeat;
    height: 419px;
    background-position: 100% 100%;
    box-sizing: border-box;
    border: 0 solid;
    display: block;
    --bg-opacity: 1;
    line-height: 2;
  }

  .max-width{
    background-color: transparent;
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    box-sizing: border-box;
    border: 0 solid;
  }

  .full-width{
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border: 0 solid;
    flex-direction: column;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .text-holder{
    background-color: transparent;
    padding-top: 0;
    width: 100%;
    box-sizing: border-box;
    border: 0 solid;
    display: block;
    justify-content: flex-start;
    margin-top: 5.25rem;
  }

  .secondary-row-holder{
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
  }

  .secondary-text-holder{
    margin-top: 5.5rem;
    width: 55%;
  }

  .header-image-holder{
    margin-top: 0rem;
    width: 45%;
  }

  .header-image-holder img{
    width: 100%;
  }

  .main-h1{
    width: 60%;
    text-align: left;
    font-size: 1.6rem;
    --text-opacity: 1;
    font-weight: 700;
    line-height: 1.24;
    /* margin-bottom: 1rem; */
    color: rgb(11, 65, 65, 1);
    padding-bottom: 0rem;
  }

  .main-h2{
    width: 95%;
    letter-spacing: 0;
    line-height: 1.5;
    font-weight: 700;
    margin-bottom: .25rem;
    font-size: 1rem;
    text-align: left;
    color: rgb(255, 204, 188, 1);
    margin-left: 0rem;
  }

  .button-holder{
    display: flex;
    flex-direction: row;
    margin-top: 0rem;
    box-sizing: border-box;
    border: 0 solid;
    line-height: 2;
  }

  .yellow-action-button{
    align-self: center;
    display: inline-block;
    clear: both;
    white-space: nowrap;
    padding: .75rem 1.875rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 1.25rem;
    margin-left: 0;
    align-self: flex-start;
    background-color: #FDFF74;
    cursor: pointer;
    outline: 0;
    text-decoration: none;
    color: black;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
    border-radius: 9999px;
    font-weight: 600;
    font-size: .75rem;
    width: auto;
    line-height: 1.375;
  }

  .yellow-action-button:hover{
    background-color: #ffffff;
  }

  .white-action-button{
    align-self: center;
    display: inline-block;
    clear: both;
    white-space: nowrap;
    padding: .75rem 1.875rem;
    padding-left: 2rem;
    padding-right: 2rem;
    align-self: flex-start;
    margin: 1.25rem;
    margin-left: 0;
    background-color: #FFFFFF;
    cursor: pointer;
    outline: 0;
    text-decoration: none;
    color: black;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
    border-radius: 9999px;
    font-weight: 600;
    font-size: .75rem;
    width: auto;
    line-height: 1.375;
    align-self: flex start;
  }

  .white-action-button:hover{
    background-color: grey;
  }
}
