.h2-boxSlider-holder{
  font-weight: 900;
  font-size: 1.5rem;
  color: #0B4141;
  margin-left: 1rem;
  margin-top: 0rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px){
  .h2-boxSlider-holder{
    display: none;
  }

  .mobile-shop-price{
    width: 60%;
    font-size: 1.25rem;
    margin: auto;
    margin-top: .5rem;
    font-weight: 900;
    text-align: center;
  }

  .description-text-starter-mobile{
    font-size: 1rem;
    margin-bottom: 1rem;
    line-height: 1.7rem;
    font-weight: 500;
    text-align: center;
  }

  .mobile-shop-a-holder{
    margin: auto;
    width: 60%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 0;
    padding-top: .5rem;
  }
}

@media (max-width: 480px) {
  .boxSlider{
    overflow-y: hidden;
  }
  .h2-boxSlider-holder{
    display: none;
  }

  h3{
    font-weight: 200;
    font-size: 1rem;
    letter-spacing: -0.015em;
    margin-left: 1rem;
  }

  .shop_box_holder{
    display: flex;
    flex-direction: row;
  }

  .selected{
    border-radius: 5%;
    height: 328px;
    width: 241px;
    border: 3px solid black;
    background-image: url('./images/box1.png');
    background-repeat: no-repeat;
    display: flex;
  }

  .border{
    border: 3px solid black;
  }

  .not_selected{
    border-radius: 5%;
    height: 328px;
    width: 241px;
    background-image: url('./images/box1.png');
    background-repeat: no-repeat;
    display: flex;
  }

  .title-price-holder{
    margin-top: 0rem;
    font-size: 1.5rem;
    margin-left: 1rem;
  }

  .mobile-shop-price{
    width: 60%;
    font-size: 1rem;
    margin: auto;
    margin-top: .5rem;
    font-weight: 900;
    text-align: center;
  }


  .description-text-starter-mobile{
    font-size: 1rem;
    margin-bottom: 1rem;
    line-height: 1.7rem;
    font-weight: 500;
    text-align: center;
  }

  .shop-description-ul-mobile{
    list-style: inherit;
    margin-bottom: .25rem;
    width: 90%;
    margin: auto;
  }

  .shop-description-li-mobile{
    list-style: inherit;
    text-align: left;
    width: 90%;
    margin: auto;
    font-size: .875rem;
    font-style: italic;
    padding-bottom: .5rem;
  }

  .mobile-shop-a-holder{
    margin: auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    padding-left: 0;
    padding-top: .5rem;
  }

  .descriptiontext_box{
    margin: auto;
    margin-top: 1.2rem;
    width: 90%;
    margin-left: 1rem;
  }

  .descriptiontext{
    width: 100%;
    margin: auto;
  }

  .button_holder{
    display: flex;
    flex-direction: inline;
  }

  .fa-arrow-circle-left{
    color: #0B4141;
    font-size: 2rem;
    margin-left: 1rem;
  }

  .fa-arrow-circle-right{
    color: #0B4141;
    font-size: 2rem;
    margin-right: 1rem;
  }

  #1{
    height: 90%;
  }

  .product-image-slider{
    width: 100%;
  }
}
