.productbg{
  background: -webkit-linear-gradient(rgba(247, 239, 224, 0.38) 0%, rgba(255, 204, 188, 0) 100%);
  background: -o-linear-gradient(rgba(247, 239, 224, 0.38) 0%, rgba(255, 204, 188, 0) 100%);
  background: linear-gradient(rgba(247, 239, 224, 0.38) 0%, rgba(255, 204, 188, 0) 100%);
}

.productholder{
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 1rem;
}

.h1-product-holder{
  width: 100%;
  margin: auto;
}

.product-h1{
  font-size: 1.5rem;
  font-weight: 800;
  margin-top: .5rem;
  margin-bottom: .5rem;
  margin-left: 1rem;
  padding-bottom: 2rem;
  text-align: center;
  margin: auto;
}

h4{
  cursor: pointer;
  text-align: center;
  font-weight: 100;
}

.h4-holder{
  cursor: pointer;
  text-align: center;
  font-weight: 100;
  border-bottom: 1px solid black;
}

h5{
  cursor: pointer;
}

.included-product-info-holder{
  width: 100%;
  margin: auto;
  font-weight: 100;
}

.product-info-holder{
  width: 60%;
  margin: auto;
  font-weight: 100;
}

.product-info-price-holder{
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  color: rgb(11,65,65, 1);
  font-weight: 900;
  font-size: 1.25rem;
}

.box-info-description-holder{
  padding-bottom: 1rem;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
}

.product-info-description-holder{
  padding-bottom: 1rem;
  border-bottom: none;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
}

.included-product-info-description-holder{
  padding-bottom: 1rem;
  border-bottom: none;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-around;
}

.description-text{
  text-align: center;
  width: 90%;
  margin: auto;
  margin-bottom: 1rem;
}

.description-bullets{
  list-style: none;
  text-align: center;
  width: 50%;
  margin: auto;
  font-size: 1rem;
  font-style: italic;
}

.description-bullet-li{
  padding-top: .1rem;
  padding-bottom: .1rem;
}

.customize-product-row-holder{
  width: 90%;
  margin: auto;
}

.customize-product-holder{
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-around;
  cursor: default;
}

.customize-image-holder{
  cursor: pointer;
}

.product-info-customize-holder{
  padding-bottom: 1rem;
  border-bottom: 1px solid black;
}

.product-other-boxes{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.product-other-boxes-image{
  height: 50%;
}

.product-other-boxes-holder{
  width: 60%;
  margin: auto;
}

.product-other-boxes-text{
  text-decoration: none;
  color: black;
  font-weight: 500;
  font-size: 1rem;
}

.product-button-holder-product{
  margin: auto;
  width: 25%;
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.product-botton-add-to-cart{
  padding: .75rem 1.875rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: 0rem;
  background-color: rgb(11,65,65, 1);
  border: 1px solid rgb(11,65,65, 1);
  cursor: pointer;
  outline: 0;
  text-decoration: none;
  color: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  border-radius: 9999px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375;
}

.product-botton-add-to-cart:hover{
  background-color: #ffffff;
  color: rgb(11,65,65, 1);
  border: 1px solid rgb(11,65,65, 1);
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid rgb(11,65,65, 1);
  border-radius: 50%;
  animation: lds-ring 1.2s linear infinite;
  border-color: rgb(11,65,65, 1) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.floating-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.floating-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #ffffff;
  border-radius: 50%;
  animation: floating-ring 1.2s linear infinite;
  border-color: #ffffff transparent transparent transparent;
}
.floating-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.floating-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.floating-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes floating-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.product-info-view-boxes-header{
  font-weight: 900;
  font-size: 1.1rem;
  line-height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #0B4141;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.included-product-column{
  width: 33%;
  padding-top: .5rem;
  padding-bottom: .5rem;
  cursor: default;
}

.customize-product-column{
  width: 33%;
  padding-top: .5rem;
  padding-bottom: .5rem;
  cursor: pointer;
}

.customize-product-column-selected{
  width: 33%;
  padding-top: .5rem;
  padding-bottom: .5rem;
  cursor: pointer;
  background-color: rgb(211,211,211);
  border-radius: .5rem;
}

.included-images{
  width: 3.75rem;
  height: 3.75rem;
}

.included-product-name{
  font-size: .875rem;
}

.customize-h4{
  font-size: 1.25rem;
  font-weight: 900;
  color: #0B4141;
  margin-block-start: .25rem;
  margin-block-end: .25rem;
}

.included-h4{
  font-size: 1.25rem;
  width: 60%;
  margin: auto;
  font-weight: 900;
  color: #0B4141;
  margin-block-start: .25rem;
  margin-block-end: .25rem;
}

.customize-header-holder{
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
}

.customize-h5{
  font-size: 1.1rem;
  font-weight: 600;
  color: #0B4141;
  margin-block-start: 0rem;
  margin-block-end: 0rem;
}

.customize-header-holder-h6{
  width: 60%;
  margin: auto;
  margin-top: .5rem;
}

.customize-h6{
  font-size: .75rem;
  font-weight: 100;
  color: black;
  margin-block-start: 0rem;
  margin-block-end: 0rem;
}

.note-box-holder{
  width: 80%;
  margin: auto;
}

.note-box{
  width: 90%;
  margin: auto;
  height: 277px;
  border-radius: 21px;
  border: 2px solid rgba(11, 65, 65, 1);
  padding: 1rem;
  font-size: 1rem;
  line-height: 1.25rem;
}

.floating-buy-button{
  position: fixed;
  bottom: 100px;
  right: 100px;
  background-color: rgba(0,0,0,0);
  height: 50px;
  margin: 0px;
  width: 200px;
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.floating-product-button-holder-product{
  width: 100%;
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.floating-product-botton-add-to-cart{
  padding: .75rem 1.875rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: 0rem;
  background-color: rgb(11,65,65, 1);
  border: 1px solid rgb(11,65,65, 1);
  cursor: pointer;
  outline: 0;
  text-decoration: none;
  color: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  border-radius: 9999px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375;
  text-align: center;
}

.floating-product-botton-add-to-cart:hover{
  background-color: #ffffff;
  color: rgb(11,65,65, 1);
  border: 1px solid rgb(11,65,65, 1);
}

@media (max-width: 768px){

  .product-button-holder-product{
    margin: auto;
    width: 80%;
    display: block;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .product-botton-add-to-cart{
    margin: auto;
    background-color: transparent;
    border: 2px solid #0B4141;
    cursor: pointer;
    outline: 0;
    text-decoration: none;
    color: #0B4141;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
    border-radius: 9999px;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.375;
    height: 40px;
    width: 167px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 34px;
  }
}

@media (max-width: 480px) {
  .product-h1{
    font-size: 1.5rem;
    font-weight: 400;
    margin-top: .5rem;
    margin-bottom: .5rem;
    margin-left: 1rem;
    padding-bottom: .25rem;
    text-align: left;
  }

  .h4-holder{
    cursor: pointer;
    text-align: center;
    font-weight: 100;
    border-bottom: 1px solid black;
  }

  .product-info-holder{
    width: 80%;
    margin: auto;
    font-weight: 100;
  }

  .product-button-holder-product{
    margin: auto;
    width: 80%;
    display: block;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .product-botton-add-to-cart{
    margin: auto;
    background-color: transparent;
    border: 2px solid #0B4141;
    cursor: pointer;
    outline: 0;
    text-decoration: none;
    color: #0B4141;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
    border-radius: 9999px;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.375;
    height: 40px;
    width: 167px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 34px;
  }

  .description-text{
    text-align: center;
    width: 100%;
    margin: auto;
    margin-bottom: 1rem;
  }

  .description-bullets{
    list-style: none;
    text-align: center;
    width: 90%;
    margin: auto;
    font-size: .875rem;
    font-style: italic;
  }

  .product-botton-add-to-cart:hover{
    background-color: #ffffff;
    color: rgb(11,65,65, 1);
    border: 1px solid rgb(11,65,65, 1);
  }

  .product-other-boxes{
    display: none;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }

  .product-info-view-boxes-header{
    display: none;
  }
}
