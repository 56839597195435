@media (max-width: 480px) {
  .boxSlider{
    overflow-y: hidden;
  }
  h2{
    font-weight: 900;
    font-size: 1.5rem;
    color: #0B4141;
    margin-left: 1rem;
    margin-top: 0rem;
    margin-bottom: 1rem;
  }

  h3{
    font-weight: 200;
    font-size: 1rem;
    letter-spacing: -0.015em;
    margin-left: 1rem;
  }

  .shop_box_holder{
    display: flex;
    flex-direction: row;
  }

  .selected{
    border-radius: 5%;
    height: 328px;
    width: 241px;
    border: 3px solid black;
    background-image: url('./images/box1.png');
    background-repeat: no-repeat;
    display: flex;
  }

  .border{
    border: 3px solid black;
  }

  .not_selected{
    border-radius: 5%;
    height: 328px;
    width: 241px;
    background-image: url('./images/box1.png');
    background-repeat: no-repeat;
    display: flex;
  }

  .descriptiontext{
    width: 100%;
    margin: auto;
  }

  .button_holder{
    display: flex;
    flex-direction: inline;
  }

  .fa-arrow-circle-left{
    color: #0B4141;
    font-size: 2rem;
    margin-left: 1rem;
  }

  .fa-arrow-circle-right{
    color: #0B4141;
    font-size: 2rem;
    margin-right: 1rem;
  }

  #1{
    height: 90%;
  }

  .product-image-slider{
    width: 100%;
  }
}
