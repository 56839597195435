.shopbg{
  background: -webkit-linear-gradient(rgba(247, 239, 224, 0.38) 0%, rgba(255, 204, 188, 0) 100%);
  background: -o-linear-gradient(rgba(247, 239, 224, 0.38) 0%, rgba(255, 204, 188, 0) 100%);
  background: linear-gradient(rgba(247, 239, 224, 0.38) 0%, rgba(255, 204, 188, 0) 100%);
}

.shopholder{
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}

.shop-h1{
  font-size: 1.75rem;
  font-weight: 400;
  margin-top: .5rem;
  margin-bottom: .5rem;
  margin-left: 1rem;
  padding-bottom: .25rem;
}

@media (max-width: 768px){

  .shopholder{
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }

  .shop-h1{
    font-size: 2.5rem;
    font-weight: 600;
    padding-bottom: .25rem;
    text-align: center;
    width: 90%;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: 480px) {
  .shop-h1{
    font-size: 1.5rem;
    font-weight: 600;
    padding-bottom: .25rem;
    text-align: center;
    width: 90%;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
