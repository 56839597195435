.ecap-container{
  background-image: url(./images/wave.svg);
  background-repeat:no-repeat;
  background-color: rgb(0,0,0,0);
  background-position: top;
  height: 800px;
  display: flex;
}

.eCap-box{
  width: 80%;
  margin: auto;
}

.eCap-box-h2{
  width: 100%;
  margin: auto;
  padding-bottom: 1rem;
}

.eCap-box-h2 h2{
  text-align: center;
  margin: auto;
  color: #ffccbc;
  font-weight: 800;
  font-size: 2.5rem;
}

.eCap-box-holder{
  width: 60%;
  margin: auto;
  margin-top: 1rem;
}

.eCap-box-button{
  display: flex;
  flex-direction: row;
  padding: 0;
  width: 100%;
  margin: auto;
  background-color: #ffffff;
  border-radius: 1rem;
  height: 2.5rem;
}

.eCap-input{
  border-radius: 1rem;
  font-size: 2rem;
  border: none;
  height: 2.3rem;
  width: 100%;
  -webkit-appearance: none;
  flex-grow: 2;
  padding-left: 1rem;
  padding-right: 1rem;
}

.eCap-input:focus{
  outline: none;
}

.eCap-button{
  background-color: #ffccbc;
  color: rgb(11, 65, 65, 1);
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 700;
  height: 2.5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  outline: none;
  border-width: 0;
  cursor: pointer;
}

.eCap-button:hover{
  background-color: rgba(11, 65, 65, 1);
  color: #ffccbc;
  text-decoration: none;
  border-color: rgba(11, 65, 65, 1);
}

.eCap-submitted{
  color: rgb(127, 181, 156, 1);
  font-size: 2rem;
}

.eCap-invalid{
  color: #DE8268;
  font-size: 2rem;
}

@media (max-width: 768px){
  .ecap-container{
    background-image: url(./images/wave.svg);
    background-repeat:no-repeat;
    background-color: rgb(0,0,0,0);
    background-position: top left;
    height: 800px;
    max-width: 768px;
    display: flex;
  }

  .eCap-box{
    width: 90%;
    margin: auto;
  }

  .eCap-box-h2{
    width: 100%;
    margin: auto;
    padding-bottom: 1rem;
  }

  .eCap-box-h2 h2{
    text-align: center;
    margin: auto;
    color: #ffccbc;
    font-weight: 800;
    font-size: 1.75rem;
  }

  .eCap-box-holder{
    width: 60%;
    margin: auto;
    margin-top: 1rem;
  }

  .eCap-box-button{
    display: inline-flex;
    flex-direction: row;
    padding: 0;
    width: 100%;
    margin: auto;
    background-color: #ffffff;
    border-radius: 1rem;
    height: 2.5rem;
  }

  .eCap-input{
    border-radius: 1rem;
    font-size: 1.5rem;
    border: none;
    height: 2.3rem;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    -webkit-appearance: none;
    flex-grow: 0;
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .eCap-input:focus{
    outline: none;
  }

  .eCap-button{
    background-color: #ffccbc;
    color: rgb(11, 65, 65, 1);
    border-radius: 1rem;
    font-size: 1rem;
    font-weight: 700;
    height: 2.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    outline: none;
    border-width: 0;
    cursor: pointer;
  }

  .eCap-button:hover{
    background-color: #ffccbc;
    text-decoration: none;
    border-color: none;
  }

  .eCap-submitted{
    color: rgb(127, 181, 156, 1);
    font-size: 2rem;
  }

  .eCap-invalid{
    color: #DE8268;
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .ecap-container{
    background-image: url(./images/wave.svg);
    background-repeat:no-repeat;
    background-color: rgb(0,0,0,0);
    background-position: top left;
    height: 800px;
    max-width: 480px;
    display: flex;
  }

  .eCap-box{
    width: 90%;
    margin: auto;
  }

  .eCap-box-h2{
    width: 100%;
    margin: auto;
    padding-bottom: 1rem;
  }

  .eCap-box-h2 h2{
    text-align: center;
    margin: auto;
    color: #ffccbc;
    font-weight: 800;
    font-size: 1.75rem;
  }

  .eCap-box-holder{
    width: 90%;
    margin: auto;
    margin-top: 1rem;
  }

  .eCap-box-button{
    display: inline-flex;
    flex-direction: row;
    padding: 0;
    width: 100%;
    margin: auto;
    background-color: #ffffff;
    border-radius: 1rem;
    height: 2.5rem;
  }

  .eCap-input{
    border-radius: 1rem;
    font-size: 1.5rem;
    border: none;
    height: 2.3rem;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    -webkit-appearance: none;
    flex-grow: 0;
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .eCap-input:focus{
    outline: none;
  }

  .eCap-button{
    background-color: #ffccbc;
    color: rgb(11, 65, 65, 1);
    border-radius: 1rem;
    font-size: 1rem;
    font-weight: 700;
    height: 2.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    outline: none;
    border-width: 0;
    cursor: pointer;
  }

  .eCap-button:hover{
    background-color: #ffccbc;
    text-decoration: none;
    border-color: none;
  }

  .eCap-submitted{
    color: rgb(127, 181, 156, 1);
    font-size: 2rem;
  }

  .eCap-invalid{
    color: #DE8268;
    font-size: 2rem;
  }
}
