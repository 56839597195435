.container {
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}

.footer {
  font-size: 0.85rem;
  padding: 20px 0;
  line-height: 1.6;
  background-color: rgba(0, 0, 0, 0);
  overflow-x: hidden;
}

.footer__text,
.footer__link,
.footer__title {
  color: rgb(11, 65, 65, 1);
  padding: 3px 15px;
  display: block;
}

.footer__link-support {
  color: rgb(11, 65, 65, 1);
  padding: 3px 15px;
  display: block;
  font-size: .6rem;
}

.footer__hr {
  height: 1px;
  border: none;
  background: #444444;
}

.footer__sections {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.footer__section {
  box-sizing: border-box;
  width: 20%;
  padding: 15px 0;
  flex-shrink: 0;
}

.footer__title {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 800;
}

.footer__link {
  text-decoration: none;
  color: rgb(11, 65, 65, 1);
  font-size: 0.85rem;
}

.footer__link:hover {
  color: #ffccbc;
}

.footer__link:hover {
  color: #ffccbc;
  background: rgba(255, 255, 255, 0.15);
}

.footerLogo {
  margin-left: 0;
  width: 12rem;
}

.footerLogoIcon {
  width: 100%;
}

.footerLogoIcon img {
  max-width: 100%;
}

.footerImageHolder {
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
}

.socialFooter-1 {
  padding-left: 1rem;
  padding-right: 0.5rem;
}

.socialFooter-1:hover {
  color: #ffccbc;
}

.socialFooter-2 {
  padding-left: 0.5rem;
}

@media screen and (max-width: 768px) {
  .footer__sections {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  .logo {
    display: none;
  }
}

/* THIS NEEDS TO BE FIXED */
@media screen and (max-width: 480px) {

  .footer__section {
    width: 50%;
  }

  .footer__sections {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: space-between;
  }
}
