header {
  width: 100%;
  position: sticky;
  background-color: rgb(0,0,0,0);
  overflow-y: hidden;
}

header .siteHeaderContainer {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  max-width: 1100px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

nav {
  max-width: 100vw;
  z-index: 500;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 2px 0 rgba(40,0,0,0.2);
}

.navLogoDesk {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 7.5rem;
}

.logoImage {
  height: 60px;
}

.logoImage img {
  max-width: 100%;
}

.navMenus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.leftMenu {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: center;
  align-items: center;
}

.leftMenuButtonText {
  font-size: 1.2rem;
  color: rgb(11, 65, 65, 1);
  font-weight: 500;
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
  line-height: 1.375;
}

.leftMenuButtonText:hover {
  color: rgb(255, 204, 188, 1);
}

.rightMenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 33.333333%;
  text-decoration: none;
}

.loginText {
  font-size: 1.2rem;
  color: #95b4bc;
  font-weight: 500;
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
  line-height: 1.375;
}

.loginText:hover {
  color: rgb(255, 204, 188, 1);
}

.signupMenuButton {
  cursor: pointer;
  outline: 0;
  text-decoration: none;
  color: #de8268;
  font-weight: 800;
  font-size: 1.2rem;
  line-height: 1.375;
}

.signupMenuButton:hover {
  color: #ffffff;
}

@media (max-width: 480px) {
  header {
    width: 100%;
    position: sticky;
    background-color: rgb(0, 0, 0, 0);
  }

  header .siteHeaderContainer {
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-weight: 600;
    max-width: 480px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
  }

  nav {
    max-width: 480px;
    z-index: 500;
    box-shadow: 0 2px 2px 0 rgba(40,0,0,0.2);
  }

  .navLogoDesk {
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 7.5rem;
  }

  .logoImage {
    height: 60px;
    width: 153px;
  }

  .logoImage img {
    height: 60px;
    width: 153px;
  }

  .navMenus {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
  }

  .leftMenu {
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: center;
    align-items: center;
  }

  .leftMenuButtonText {
    font-size: 0.6rem;
    color: rgb(11, 65, 65, 1);
    font-weight: 500;
    text-align: center;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    text-decoration: none;
    display: inline-block;
    line-height: 1.375;
  }

  .leftMenuButtonText:hover {
    color: rgb(255, 204, 188, 1);
  }

  .rightMenu {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 28%;
    text-decoration: none;
  }

  .loginText {
    font-size: 0.7rem;
    color: #95b4bc;
    font-weight: 500;
    text-align: center;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    text-decoration: none;
    display: inline-block;
    line-height: 1.375;
  }

  .loginText:hover {
    color: rgb(255, 204, 188, 1);
  }

  .signupMenuButton {
    cursor: pointer;
    outline: 0;
    text-decoration: none;
    color: #de8268;
    font-weight: 800;
    font-size: 0.7rem;
    line-height: 1.375;
    margin-left: 0.25rem;
  }

  .signupMenuButton:hover {
    color: #ffffff;
  }

  .hamburger-menu{
    height: 90%;
  }
}

:root {
  --bg: #F5F5F5;
  --bg-accent: #dedfd2;
  --text-color: #242526;
  --nav-size: 60px;
  --border-radius: 8px;
  --speed: 500ms;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

/* Top Navigation Bar */

/* <nav> */
.navbar {
  height: var(--nav-size);
  background-color: var(--bg);
  padding: 0 1rem;
  border-bottom: var(--border);
}

/* <ul> */
.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

/* <li> */
.nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

/* Dropdown Menu */

.dropdown {
  position: absolute;
  top: 58px;
  width: 300px;
  transform: translateX(-37.5%);
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden;
  transition: height var(--speed) ease;
}
.menu {
  width: 100%;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: #e2e4db;
}

.icon-right {
  margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}
