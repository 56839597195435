/* index.js (blog) */

.blogbg {
  background: -webkit-linear-gradient(
    rgba(247, 239, 224, 0.38) 0%,
    rgba(255, 204, 188, 0) 100%
  );
  background: -o-linear-gradient(
    rgba(247, 239, 224, 0.38) 0%,
    rgba(255, 204, 188, 0) 100%
  );
  background: linear-gradient(
    rgba(247, 239, 224, 0.38) 0%,
    rgba(255, 204, 188, 0) 100%
  );
  overflow-x: hidden;
}

.blog-background-holder {
  background-position: 100% 10%;
  background-repeat: no-repeat;
  width: 100%;
  height: 120rem;
}

.blog-header-image-holder {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.blog-header-image {
  margin-top: -10rem;
  width: 107rem;
}

.blog {
  margin-top: -40rem;
  flex-direction: column;

  margin-left: auto;
  margin-right: auto;
  max-width: 106.25rem;
  background-size: contain;
  background-repeat: no-repeat;
  height: 70rem;
  background-position: 100% 100%;
  box-sizing: border-box;
  border: 0 solid;
  display: block;
  --bg-opacity: 1;
  line-height: 2;
}

.flower1 {
  margin-left: 4rem;
}

.flower2 {
  margin-left: -14.6rem;
  margin-bottom: -4rem;
}

.flower3 {
  margin-left: -0.5rem;
  margin-bottom: -2rem;
}

.resources {
  margin: -5rem auto auto auto;
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.5rem;
  text-align: center;
  color: #ffffff;
}

.blog-search-container {
  display: flex;
}

.blog-search {
  display: flex;
  flex-direction: row;
  padding: 0;
  width: 35%;
  /* margin: 1rem auto auto auto; */
  margin: auto auto auto 30%;
  background-color: #ffffff;
  border-radius: 1rem;
  height: 2.5rem;
}

.blog-search-input {
  border-radius: 1rem;
  font-size: 2rem;
  border: none;
  height: 2.3rem;
  width: 100%;
  -webkit-appearance: none;
  flex-grow: 2;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.blog-search-input:focus {
  outline: none;
}

.blog-search-button {
  background-color: #ffffff;
  color: rgb(11, 65, 65, 1);
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 700;
  height: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  outline: none;
  border-width: 0;
  cursor: pointer;
}

.blog-search-ellipse {
  width: 1.2rem;
  margin-bottom: -0.2rem;
  padding: none;
  border: none;
}

.blog-search-line {
  width: 0.6rem;
  margin-bottom: 0.7rem;
  margin-left: 1rem;
  position: relative;
  top: -0.2rem;
}

.blog-sortby-container {
  display: flex;
  justify-content: flex-end;
  /* margin-top: 1rem; */
  margin-right: 21rem;
  /* margin-left: 20rem; */
  z-index: 1;
  cursor: pointer;
}

.blog-sortby {
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 2.5rem;
  text-decoration-line: underline;
  color: #ffffff;
  z-index: 99;
}

.blog-chevron {
  margin-left: 1rem;
  margin-top: 0.2rem;
  width: 1rem;
  transform: rotate(0deg);
  transition: 0.15s ease;
}

.blog-chevron.open {
  fill: #ffccbc;
  transform: rotate(180deg);
  transition: 0.15s ease;
}

.blog-sortby-menu {
  position: absolute;
  background: #ffccbc;
  border-radius: 1rem;

  width: 11.5rem;
  height: 7.3rem;

  margin-left: -3rem;
  margin-top: 11rem;

  display: flex;
  justify-content: center;

  pointer-events: none;
  opacity: 0;
  transition: opacity 0.12s cubic-bezier(0, 0, 1, 1);

  z-index: 9999;
}

.blog-sortby-menu.active {
  pointer-events: auto;
  opacity: 1 !important;
  transition: opacity 0.2s cubic-bezier(0.5, 0, 0.88, 0.77) !important;
}

.blog-sortby-menu ul {
  list-style: none;
  padding: auto;
  margin: auto;
}

.blog-sortby-menu li {
  width: 100%;
  height: 2.75rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 2.5rem;

  text-align: center;

  color: #484848;

  text-decoration: none !important;
  cursor: pointer;
}

.blog-sortby-menu p:hover {
  background: #f5bead;
}

.blog-sortby-menu p:active {
  background: none !important;
}

.blog-sortby-menu li#border {
  width: 100%;
  border-bottom: 0.044rem solid #dddddd;
  margin: 0.625rem 0;
}

.blog-sortby-menu li.active {
  font-weight: 900;
}

.blog-sortby-selected {
  margin-top: -0.8rem;
  width: 9.44rem;
  height: 1.57rem;
  position: absolute;

  background: #ffccbc;
  border-radius: 1.406rem;
  z-index: 1;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}

.blog-sortby-selected-text {
  margin-left: 0.3rem;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 2.5rem;
  /* or 333% */
}

.blog-sortby-selected-x {
  margin-left: 1.2rem;
}

.blog-post-container {
  width: 55%;
  margin: 2rem auto 0;
  display: grid;
  grid-template-columns: 30.18% 30.18% auto;
  column-gap: 3.51rem;
  row-gap: 4.61rem;
}

.blog-post {
  width: 16.125rem;
  height: 22rem;
  background: #ffffff;
  box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.05);
  border-radius: 2.25rem;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 1px solid black;
}

.blog-post-date {
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 2.2rem;
  color: #000000;
}

.blog-post-image {
  width: 12rem;
  height: 12rem;
  filter: drop-shadow(0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.1));
  border-radius: 1.188rem;
  object-fit: cover;
}

.post-divider {
}

.blog-post-title-holder {
  display: flex;
  justify-content: center;
}

.blog-post-title {
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.25rem;
  color: #000000;
  width: 80%;
  margin-top: 0.5rem;
  height: 2.2rem;
}

.blog-post-divider {
  width: 14.125rem;
  height: 0rem;
  border: 0.063rem solid #000000;
  text-align: center;
}

.blog-post-author {
  font-style: normal;
  font-weight: normal;
  font-size: 0.813rem;
  line-height: 2.5rem;
  color: #000000;
  margin-top: -0.25rem;
}

.blog-back-to-top {
  position: fixed;
  right: 3rem;
  bottom: 3rem;
  width: 9.25rem;
  height: 3.625rem;

  background: #ffccbc;
  border-radius: 1.813rem;
  border: none;
  cursor: pointer;

  font-style: normal;
  font-weight: 900;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  color: #000000;
}

/* blogPost.js */

.blog-back-holder {
  margin-left: 3.163rem;
}

.blog-back {
  font-style: normal;
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 2.5rem;
  text-decoration-line: none;
  color: #0b4141;
  margin-bottom: 3.938rem;
}

.blog-back-button {
  width: 0.768rem;
  margin-right: 0.893rem;
}

.blogPost-container {
  width: 50%;
  margin: auto;
}

.blogPost-image {
  width: 24rem;
  height: 24rem;
  filter: drop-shadow(0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.1));
  border-radius: 1.688rem;
  object-fit: cover;
}

.blogPost-title {
  font-style: normal;
  font-weight: 900;
  font-size: 2.25rem;
  line-height: 2.5rem;
  color: #0b4141;
  margin-top: 2.8rem;
}

.blogPost-author {
  font-style: normal;
  font-weight: normal;
  font-size: 0.813rem;
  line-height: 2.5rem;
  color: #000000;
  margin-top: 1.2rem;
}

.blogPost-content {
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 2.5rem;
  color: #000000;
  margin-top: -1rem;
}

/* MOBILE CSS */
@media (max-width: 480px) {
  /* index.js (blog) */

  .blogbg {
    background: -webkit-linear-gradient(
      rgba(247, 239, 224, 0.38) 0%,
      rgba(255, 204, 188, 0) 100%
    );
    background: -o-linear-gradient(
      rgba(247, 239, 224, 0.38) 0%,
      rgba(255, 204, 188, 0) 100%
    );
    background: linear-gradient(
      rgba(247, 239, 224, 0.38) 0%,
      rgba(255, 204, 188, 0) 100%
    );
  }

  .blog-background-holder {
    background-position: 100% 10%;
    background-repeat: no-repeat;
    width: 100%;
    height: 120rem;
  }

  .blog-header-image-holder {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .blog-header-image {
    margin-top: -10rem;
    width: 107rem;
  }

  .blog {
    margin-top: -26rem;
    flex-direction: column;
    height: 70rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    box-sizing: border-box;
    border: 0 solid;
    display: block;
    --bg-opacity: 1;
    line-height: 2;
  }

  .flower1 {
    margin-left: -1.5rem;
    margin-top: -4rem;
  }

  .flower2 {
    margin-left: -14.6rem;
    margin-bottom: -4rem;
  }

  .flower3 {
    margin-left: -0.5rem;
    margin-bottom: -2rem;
  }

  .resources {
    margin: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 2.5rem;
    text-align: center;
    color: #ffffff;
  }

  .blog-search-container {
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .blog-search {
    display: flex;
    flex-direction: row;
    padding: 0;
    width: 75%;
    margin: 1rem auto auto auto;
    background-color: #ffffff;
    border-radius: 1rem;
    height: 2.5rem;
  }

  .blog-search-input {
    border-radius: 1rem;
    font-size: 2rem;
    border: none;
    height: 2.3rem;
    width: 100%;
    -webkit-appearance: none;
    flex-grow: 2;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .blog-search-input:focus {
    outline: none;
  }

  .blog-search-button {
    background-color: #ffffff;
    color: rgb(11, 65, 65, 1);
    border-radius: 1rem;
    font-size: 1rem;
    font-weight: 700;
    height: 2.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    outline: none;
    border-width: 0;
    cursor: pointer;
  }

  .blog-search-ellipse {
    width: 1.2rem;
    margin-bottom: -0.2rem;
    padding: none;
    border: none;
  }

  .blog-search-line {
    width: 0.6rem;
    margin-bottom: 0.7rem;
    margin-left: 1rem;
    position: relative;
    top: -0.2rem;
  }

  .blog-sort-container {
  }

  .blog-sortby-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 0rem;
    margin-right: 1rem;
    margin-left: 1rem;
    z-index: 1;
    cursor: pointer;
  }

  .blog-sortby {
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 2.5rem;
    text-decoration-line: underline;
    color: #ffffff;
    z-index: 99;
    width: auto;
  }

  .blog-chevron {
    margin-left: 1rem;
    margin-top: 0.2rem;
    width: 1rem;
    transform: rotate(0deg);
    transition: 0.15s ease;
  }

  .blog-chevron.open {
    fill: #ffccbc;
    transform: rotate(180deg);
    transition: 0.15s ease;
  }

  .blog-sortby-menu {
    position: relative;
    background: #ffccbc;
    border-radius: 1rem;

    width: 11.5rem;
    height: 5.5rem;
    margin-top: -0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 50%;
    display: flex;
    justify-content: center;

    pointer-events: none;
    opacity: 0;
    transition: opacity 0.12s cubic-bezier(0, 0, 1, 1);

    z-index: 9999;
  }

  .blog-sortby-menu.active {
    pointer-events: auto;
    opacity: 1 !important;
    transition: opacity 0.2s cubic-bezier(0.5, 0, 0.88, 0.77) !important;
  }

  .blog-sortby-menu ul {
    list-style: none;
    padding: auto;
    margin: auto;
  }

  .blog-sortby-menu li {
    width: 100%;
    height: 2.75rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 2.5rem;

    text-align: center;

    color: #484848;

    text-decoration: none !important;
    cursor: pointer;
  }

  .blog-sortby-menu p:hover {
    background: #f5bead;
  }

  .blog-sortby-menu p:active {
    background: none !important;
  }

  .blog-sortby-menu li#border {
    width: 100%;
    border-bottom: 0.044rem solid #dddddd;
    margin: 0.625rem 0;
  }

  .blog-sortby-menu li.active {
    font-weight: 900;
  }

  .blog-sortby-selected {
    margin-top: -0.8rem;
    margin-left: 60%;
    margin-bottom: 0.8rem;
    width: 9.44rem;
    height: 1.57rem;
    position: relative;

    background: #ffccbc;
    border-radius: 1.406rem;
    z-index: 99999;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }

  .blog-sortby-selected-text {
    margin-left: 0.3rem;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 2.5rem;
    /* or 333% */
  }

  .blog-sortby-selected-x {
    margin-left: 1.2rem;
  }

  .blog-post-container {
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 0.25rem;
    row-gap: 0.5rem;
  }

  .blog-post {
    width: 10rem;
    height: 14.188rem;
    margin: auto;
    background: #ffffff;
    box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.05);
    border-radius: 2.25rem;
    border: 1px solid black;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .blog-post-date {
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 2.2rem;
    color: #000000;
  }

  .blog-post-image {
    width: 120px;
    height: 120px;
    filter: drop-shadow(0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.1));
    border-radius: 1.188rem;
    object-fit: cover;
  }

  .post-divider {
    width: 80%;
  }

  .blog-post-title-holder {
    display: flex;
    justify-content: center;
  }

  .blog-post-title {
    font-style: normal;
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #000000;
    width: 80%;
    margin-top: 0.5rem;
    height: 1rem;
  }

  .blog-post-divider {
    width: 14.125rem;
    height: 0rem;
    border: 0.063rem solid #000000;
    text-align: center;
  }

  .blog-post-author {
    font-style: normal;
    font-weight: normal;
    font-size: 0.6rem;
    line-height: 1rem;
    color: #000000;
    margin-top: 0rem;
  }

  .blog-back-to-top {
    position: fixed;
    right: 0rem;
    top: 38rem;
    width: 8rem;
    height: 3.625rem;

    background: #ffccbc;
    border-radius: 1.813rem 0rem 0rem 0rem;
    border: none;
    cursor: pointer;

    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    display: flex;
    align-items: center;
    color: #000000;
  }

  /* blogPost.js */

  .blog-back-holder {
    margin-left: 1rem;
  }

  .blog-back {
    font-style: normal;
    font-weight: 900;
    font-size: 0.75rem;
    line-height: 2.5rem;
    text-decoration-line: none;
    color: #0b4141;
    margin-bottom: 1rem;
  }

  .blog-back-button {
    width: 0.4rem;
    margin-right: 0.5rem;
  }

  .blogPost-container {
    width: 90%;
    margin: auto;
  }

  .blogPost-image {
    width: 300px;
    height: 300px;
    filter: drop-shadow(0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.1));
    border-radius: 1.688rem;
    margin: auto;
    object-fit: cover;
  }

  .blogPost-title {
    font-style: normal;
    font-weight: 900;
    font-size: 2.25rem;
    line-height: 2.5rem;
    color: #0b4141;
    margin-top: 2.8rem;
  }

  .blogPost-author {
    font-style: normal;
    font-weight: normal;
    font-size: 0.813rem;
    line-height: 2.5rem;
    color: #000000;
    margin-top: 1.2rem;
  }

  .blogPost-content {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 2.5rem;
    color: #000000;
    margin-top: -1rem;
  }
}
