.boxSlider{
  overflow-y: hidden;
}

h3{
  font-weight: 200;
  font-size: 1rem;
  letter-spacing: -0.015em;
  margin-left: 1rem;
}

.shop_box_holder{
  display: flex;
  flex-direction: row;
}

.selected{
  border-radius: 5%;
  height: 328px;
  width: 241px;
  border: 3px solid black;
  background-image: url('./images/box1.png');
  background-repeat: no-repeat;
  display: flex;
}

.border{
  border: 3px solid black;
}

.not_selected{
  border-radius: 5%;
  height: 328px;
  width: 241px;
  background-image: url('./images/box1.png');
  background-repeat: no-repeat;
  display: flex;
}

.title-price-holder{
  margin-top: 0rem;
  font-size: 1.5rem;
  margin-left: 1rem;
}

.product-price-holder{
  font-size: 1rem;
}

.descriptiontext_box{
  margin: auto;
  margin-top: 1.2rem;
  width: 80%;
  margin-left: 1rem;
}

.descriptiontext{
  width: 100%;
  margin: auto;
}

.description-text-starter{
  margin-bottom: 1rem;
}

.shop-description-ul{
  list-style: inherit;
  margin-bottom: 1rem;
  width: 90%;
  margin: auto;
}

.shop-description-li{
  list-style: inherit;
  text-align: left;
  width: 90%;
  margin: auto;
  font-size: .875rem;
  font-style: italic;
  padding-bottom: 1rem;
}

.button_holder{
  display: flex;
  flex-direction: inline;
}

.fa-arrow-circle-left{
  color: #0B4141;
  font-size: 2rem;
  margin-left: 1rem;
}

.fa-arrow-circle-right{
  color: #0B4141;
  font-size: 2rem;
  margin-right: 1rem;
}

.product-button-holder{
  margin: auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  padding-left: 0rem;
  padding-top: 1rem;
}

#1{
  height: 90%;
}

.desktop-shop-product-holder{
  width: 33%;
}

.right-box-yellow-action-button{
  padding: .75rem 3rem;
  margin-left: 0rem;
  background-color: #0b4141;
  border: 1px solid #0b4141;
  cursor: pointer;
  outline: 0;
  text-decoration: none;
  color: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  border-radius: 9999px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375;
}

.right-box-yellow-action-button:hover{
  background-color: #ffffff;
  color: rgb(11,65,65, 1);
  border: 1px solid rgb(11,65,65, 1);
}

@media (max-width: 768px){
  .title-price-holder{
    font-size: 2rem;
    font-weight: 500;
    margin-left: 1rem;
    width: 60%;
    margin: auto;
    margin-top: 1rem;
    text-align: center;
    color: #0b4141;
  }

  .product-price-holder{
    font-size: 1rem;
  }

  .descriptiontext_box{
    margin: auto;
    margin-top: 1.2rem;
    width: 60%;
  }

  .descriptiontext{
    width: 100%;
    margin: auto;
  }

  .description-text-starter{
    margin-bottom: 1rem;
  }
}

@media (max-width: 480px){
  .title-price-holder{
    font-size: 1.5rem;
    font-weight: 500;
    width: 80%;
    margin: auto;
    margin-top: 1rem;
    text-align: center;
    color: #0b4141;
  }

  .product-price-holder{
    font-size: 1rem;
  }

  .descriptiontext_box{
    margin: auto;
    margin-top: 1.2rem;
    width: 80%;
  }

  .descriptiontext{
    width: 100%;
    margin: auto;
  }

  .description-text-starter{
    margin-bottom: 1rem;
  }
}
