.background-holder{
  background-image: url(./images/Header.png);
  background-position: 100% 10%;
  background-repeat: no-repeat;
  width: 100%;
  height: 900px;
  z-index: 999999;
}

@media (max-width: 768px){
  .background-holder{
    background-image: url(./images/Header.png);
    background-position: 70% 0%;
    background-repeat: no-repeat;
    background-clip: content-box;
    background-size: auto;
    width: 100%;
    height: 900px;
    z-index: 999999;
    overflow-x: hidden;
  }
}

@media (max-width: 480px){
  .background-holder{
    background-image: url(./images/MobileHeader.png);
    background-position: none;
    background-repeat: no-repeat;
    background-clip: content-box;
    background-size: auto;
    width: 100%;
    height: 700px;
    z-index: 999999;
    overflow-x: hidden;
  }
}
